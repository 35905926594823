import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Masks from "./assets/images/Build.png";
import Store from "./assets/images/store.png";
import Apps from "./assets/images/play.png";
import Desktop from "./assets/images/desk.png";
import Laptop from "./assets/images/laptop.png";
import Tabs from "./assets/images/tabs.png";
import Iphone from "./assets/images/iphone.png";
import Iphonex from "./assets/images/iphonex.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { connect } from "react-redux";
import { ContactModalAction } from "../Actions/ContactModalAction";


import "./Component.css";

function Build(props) {

  const setModalIsOpenToTrue = () => {
    props.contactModalAction({ type: 'open', payload: {} });
  }

  return (
    <>
      <div
        className="layers buildSec position-relative"
        style={{
          background: `url(${Masks}) no-repeat center/cover`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container">
          <Row>
            <Col lg={{ span: 11, offset: 1 }}>
              <Row>
                <Col lg={{ span: 5 }}>
                  <div className="brand">
                    Build Your Own Brand
                    <p>
                      {" "}
                      Want to see Live Scoring & Video in action?
                    </p>
                    <div className="sign-i">

                      <Button
                        className="btn btn-secondary mainBtn light-shadow btn-sm btn btn-primary"
                        onClick={setModalIsOpenToTrue}
                      >
                        REQUEST A DEMO <ArrowForwardIcon />
                      </Button>


                    </div>
                    <p>Ready to score your next match? Get the App</p>
                  </div>
                  <div className="now-ap">
                    <Row>
                      <Col lg={{ span: 6 }}>
                        <div className="d-flex justify-content-start">
                          <a
                            href="https://apps.apple.com/us/app/crickslab/id1489726470"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Store} alt="" />
                          </a>
                        </div>
                      </Col>
                      <Col lg={{ span: 6 }}>
                        <div className="c">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.antiquelogic.crickslab"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Apps} alt="" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={{ span: 7 }}>
                  <Row>
                    <Col lg={{ span: 12 }}>
                      <div className="mb--5">
                        <div className="desk">
                          <img src={Desktop} alt="" />
                        </div>

                        <div className="lapt">
                          <img src={Laptop} alt="" />
                        </div>

                        <div className="tab">
                          <img src={Tabs} alt="" />
                        </div>

                        <div className="mobile">
                          <img src={Iphone} alt="" />
                        </div>

                        <div className="iphonx">
                          <img src={Iphonex} alt="" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    contactModalState: state.contactModalState,
  };
};

const mapDispatchToProps = {
  contactModalAction: ContactModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Build);
