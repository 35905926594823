import React from "react";
import { Row, Col } from "react-bootstrap";
import Layer from "./assets/images/Layers.png";
import Gray1 from "./assets/images/gray1.png";
import Gray2 from "./assets/images/gray2.png";
import Gray3 from "./assets/images/gray3.png";
import Gray4 from "./assets/images/gray4.png";
import Gray5 from "./assets/images/gray5.png";
import one from "./assets/images/1.png";
import two from "./assets/images/2.png";
import three from "./assets/images/3.png";
import four from "./assets/images/4.png";
import five from "./assets/images/5.png";

import "./Component.css";

function Graphics() {
  return (
    <>
      <div
        className="layer"
        style={{
          background: `url(${Layer}) no-repeat center/cover`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="container">
          <Row>
            <Col lg={{ span: 12 }}>
              <div className="big-n">
                Live Stream Graphics Features
                <p>Increase your user experience with professional, animated, live score cards.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 11, offset: 1 }}>
              <Row className="mrg-tp">
                <Col lg={{ span: 8 }}>
                  <div className="one-s d-flex white-mrg justify-content-start">
                    <img src={Gray1} alt="" />
                  </div>
                  <div className="one d-flex white-mrg justify-content-start">
                    <img src={one} alt="" />
                  </div>
                </Col>
                <Col lg={{ span: 4 }}>
                  <div className="multipl">
                    Multiple Software Support
                    <p>
                      {" "}
                      Supported by vMix, Xsplit, Wirecast, OBS. Upgrade your
                      broadcasting gear with easy plug and play graphics.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mrg-tp">
                <Col
                  xs={{ order: "last", span: 12 }}
                  lg={{ order: "first", span: 4 }}
                >
                  <div className="multipl ">
                    Instant Updates
                    <p>
                      {" "}
                      Score added in the Crickslab app are reflected instantly in the live scorecard graphics.
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ order: "first", span: 12 }}
                  lg={{ order: "last", span: 8 }}
                >
                  <div className="one-s">
                    <img src={Gray2} alt="" />
                  </div>
                  <div className="two d-flex white-mrg justify-content-end">
                    <img src={two} alt="" />
                  </div>
                </Col>
              </Row>
              <Row className="mrg-tp">
                <Col lg={{ span: 8 }}>
                  <div className="one-s d-flex white-mrg justify-content-start">
                    <img src={Gray3} alt="" />
                  </div>
                  <div className="three d-flex white-mrg justify-content-start">
                    <img src={three} alt="" />
                  </div>
                </Col>
                <Col lg={{ span: 4 }}>
                  <div className="multipl ">
                    Charts, Diagrams & more
                    <p>
                      Show player statistics, manhattan charts, wagon wheel,
                      score trend, etc
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mrg-tp">
                <Col
                  xs={{ order: "last", span: 12 }}
                  lg={{ order: "first", span: 4 }}
                >
                  <div className="multipl ">
                    90+ Layouts and Transitions
                    <p>
                      {" "}
                      Each layout is animated and shows a particular statistics
                      or data needed for that moment of time. Show particular
                      player career, teams, custom message, match summary, lowe
                      third, batting list and bowling list, etc
                    </p>
                  </div>
                </Col>
                <Col
                  xs={{ order: "first", span: 12 }}
                  lg={{ order: "last", span: 8 }}
                >
                  <div className="one-s">
                    <img src={Gray4} alt="" />
                  </div>
                  <div className="four d-flex white-mrg justify-content-end">
                    <img src={four} alt="" />
                  </div>
                </Col>
              </Row>
              <Row className="mrg-tp">
                <Col lg={{ span: 8 }}>
                  <div className="one-s d-flex white-mrg justify-content-start">
                    <img src={Gray5} alt="" />
                  </div>
                  <div className="five d-flex white-mrg justify-content-start">
                    <img src={five} alt="" />
                  </div>
                </Col>
                <Col lg={{ span: 4 }}>
                  <div className="multipl ">
                    Cloud Based Controller
                    <p>
                      {" "}
                      Control layouts anywhere around the world from your computer or phone.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Graphics;
