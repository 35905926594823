import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import { Switch, Route, Redirect } from "react-router-dom";
import CryptoJS from "crypto-js";

const App = () => {
  React.useEffect(() => {
    if (!localStorage.getItem("Raw_id")) {
      fetch("/info-health")
        .then((res) => {
          res.json().then((response) => {
            const data = JSON.stringify(response.data);
            var ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify(data),
              process.env.REACT_APP_SECRET_KEY
            ).toString();

            localStorage.setItem("Raw_id", ciphertext);
          });
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  }, []);
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default App;
