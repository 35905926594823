import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Layers from "./assets/images/lyer.png";
import Player from "./assets/images/Player.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Rectangle from "./assets/images/Rectangle.png";
import Twitter from "./assets/images/Twitter.png";
import YouTube from "./assets/images/YouTube.png";
import LinkedIn from "./assets/images/LinkedIn.png";
import Whatsapp from "./assets/images/whatsapp.png";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import Brands from "./assets/images/Brands.png";
import "./Component.css";

import { connect } from "react-redux";
import { ContactModalAction } from "../Actions/ContactModalAction";

function Broad(props) {

  const setModalIsOpenToTrue = () => {
    props.contactModalAction({ type: 'open', payload: {} });
  }

  return (
    <>
      <div
        className="position-absolute"
        style={{
          top: 0,
          left: 0,
          width: "100vw",
          height: "100%",
          zIndex: -1,
          background: "linear-gradient(135deg, #0FB918 0%, #59B6D3 100%)",
          mixBlendMode: "normal",
          opacity: 0.2,
        }}
      >
        <span style={{ visibility: "hidden" }}>...</span>
      </div>
      <div
        className="backgg"
        style={{
          background: `url(${Layers}) no-repeat center/cover`,
          backgroundSize: "100% 100%",
        }}
      >
        <Row>
          <Col lg={{ span: 11, offset: 1 }}>
            <Row className="gradient-c">
              <Col lg={{ span: 6 }}>
                <div className="graph">
                  <img src={Player} alt="Player" />
                </div>
              </Col>

              <Col
                lg={{ span: 6 }}
              // style={{ position: "absolute", zIndex: 2, right: 10 }}
              >
                <div className="gear-s">
                  Upgrade Your Broadcasting Gear
                  <p style={{ width: "80%" }}>
                    Get in touch using the below form. Fill it and our sales
                    team will contact you for the discussion.
                  </p>
                </div>

                <Button
                  size="sm"
                  variant="secondary"
                  className="light-shadow mainBtn"
                  onClick={setModalIsOpenToTrue}
                >
                  GO TO FORM <ArrowForwardIcon />
                </Button>

                <div className="instra">
                  <img src={Brands} alt="Brands" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="ggg">
        <div
          className="backg"
          style={{
            background: `url(${Rectangle}) no-repeat center/cover`,
            backgroundSize: "100% 100%",
          }}
        >
          <Row className="">
            <Col lg={{ span: 11, offset: 1 }}>
              <Row className="graphi">
                <Col lg={{ span: 6 }}>
                  <Row>
                    <Col lg={{ span: 3 }}>
                      <a className="scroll-f featr" href="#features">
                        Features
                      </a>
                    </Col>
                    <Col lg={{ span: 3 }}>
                      <a className="scroll-f featr" href="#graphics">
                        Graphics
                      </a>
                    </Col>
                    <Col lg={{ span: 3 }}>
                      <div className="featr">CricksLab.com</div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={{ span: 4 }}>
                  <div className="fea d-flex justify-content-end">
                    <a href="https://twitter.com/CricksLab"
                      rel="noreferrer"
                      target="_blank">
                      <img src={Twitter} alt="Twitter" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC53M-wFABQaem0VcFLl-XdQ"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={YouTube} alt="Youtube" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/13631348"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={LinkedIn} alt="Linkedin" />
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="smart">
                <Col lg={{ span: 4 }}>
                  <div className="heigth">
                    <RoomOutlinedIcon style={{ color: "#0FB918" }} /> 12A-04,
                    Damac Smart Heights, Tecom, Dubai, UAE.
                  </div>
                </Col>
                <Col lg={{ span: 2 }}>
                  <a
                    className="heigth"
                    href="tel:+971559987521"
                    style={{ color: "#000" }}
                  >
                    <PhoneInTalkOutlinedIcon style={{ color: "#0FB918" }} /> +971
                    55 9987521
                  </a>
                </Col>
                <Col lg={{ span: 2 }}>
                  <a
                    className="heigth"
                    href="mailto:sales@crickslab.com"
                    style={{ color: "#000" }}
                  >
                    <MailOutlineOutlinedIcon style={{ color: "#0FB918" }} />{" "}
                    sales@crickslab.com
                  </a>
                </Col>
                <Col lg={{ span: 4 }}>
                  <div className="all-s">
                    © Copyright {new Date().getFullYear()}. All Rights Reserved.
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    contactModalState: state.contactModalState,
  };
};

const mapDispatchToProps = {
  contactModalAction: ContactModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Broad);
