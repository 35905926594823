import React from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import Mask from "./assets/images/mask.png";
import Score from "./assets/images/Scoring.png";
import App from "./assets/images/apple.png";
import Google from "./assets/images/google.png";
import Selphone from "./assets/images/aqqa.png";
import "./Component.css";

function Features() {
  return (
    <>
      <Row
        style={{
          background: `url(${Mask}) no-repeat center/cover`,
          backgroundSize: "100% 100%",
        }}
      >
        <Col lg={{ span: 11, offset: 1 }}>
          <Row>
            <Col lg={{ span: 11 }}>
              <div className="score">
                Scoring Features
                <p>
                Built to cope with all the situations a scorer may face. Score once, display it everywhere!
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 11, offset: 1 }}>
              <Row className="understand-s">
                <Col lg={{ span: 3 }}>
                  <div className="simple">
                    Simple and Powerful
                    <p>
                    Easy to understand buttons for cricket match scoring.
                    </p>
                  </div>
                  <div className="simple und">
                    Dynamically Add Players
                    <p>
                    Add players on the fly when they enter the field to bat and bowl. No need for pre-match player lists.
                    </p>
                  </div>
                  <div className="simple und">
                    Merge and Replace Player
                    <p>
                      Add new player when the match starts and merge it with
                      existing records later.
                    </p>
                  </div>
                  <div className="simple und">
                    Negative Scoring
                    <p>
                      Say YES to organizers needs. ex: Customize buttons to
                      reduce -5 runs for a wicket.
                    </p>
                  </div>
                </Col>
                <Col lg={{ span: 5 }}>
                  <div className="cel-phn">
                    <img src={Score} alt="" />
                  </div>
                </Col>
                <Col lg={{ span: 3 }}>
                  <div className="undo">
                    Create Any Match Type
                    <p>
                    From Test to ODI, T10, T20, Big Bash or completely custom, we support all your needs.
                    </p>
                  </div>
                  <div className="undo undos">
                    Undo Last Score
                    <p>
                      Correct scoring errors by undoing last scoring operation.
                    </p>
                  </div>
                  <div className="undo undos">
                    Simultaneous Match Scoring
                    <p>
                    Score multiple matches across different tournaments at the same time.
                    </p>
                  </div>
                  <div className="undo undos">
                    Edit Every Detail
                    <p>
                      From team name to individual player statistics, everything
                      can be edited. You control your data.
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="carousel-cont">
                <Col lg={{ span: 5 }}>
                  <div className="cel-phns">
                    <img src={Selphone} alt="" />
                  </div>
                </Col>
                <Col lg={{ span: 12 }}>
                  <div className="car-ousels">
                    <Carousel>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="simple">
                            Simple and Powerful
                            <p>
                            Easy to understand buttons for cricket match scoring.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="simple und">
                            Dynamically Add Players
                            <p>
                            Add players on the fly when they enter the field to bat and bowl. No need for pre-match player lists.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="simple und">
                            Merge and Replace Player
                            <p>
                              Add new player when the match starts and merge it
                              with existing records later.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="simple und">
                            Negative Scoring
                            <p>
                              Say YES to organizers needs. ex: Customize buttons
                              to reduce -5 runs for a wicket.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="undo">
                            Create Any Match Type
                            <p>
                            From Test to ODI, T10, T20, Big Bash or completely custom, we support all your needs.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="undo undos">
                            Undo Last Score
                            <p>
                              Correct scoring errors by undoing last scoring
                              operation.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="undo undos">
                            Simultaneous Match Scoring
                            <p>
                            Score multiple matches across different tournaments at the same time.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption>
                          <div className="undo undos">
                            Edit Every Detail
                            <p>
                              From team name to individual player statistics,
                              everything can be edited. You control your data.
                            </p>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={{ span: 11 }}>
              <div className="app-n">
                <p> Interested? Get the App Now! </p>
              </div>
            </Col>
            <Col lg={{ span: 6 }}>
              <div className="d-flex app-gog justify-content-end">
                <a
                  href="https://apps.apple.com/us/app/crickslab/id1489726470"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={App} alt="" />
                </a>
              </div>
            </Col>
            <Col lg={{ span: 6 }}>
              <div className="d-flex app-gog justify-content-start">
                <a
                  href="https://play.google.com/store/apps/details?id=com.antiquelogic.crickslab"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Google} alt="" />
                </a>
              </div>
            </Col>
          </Row>
          <hr style={{ width: "80%", color: "2px solid #E8E8E8" }} />
        </Col>
      </Row>
    </>
  );
}

export default Features;
