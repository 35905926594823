import CryptoJS from "crypto-js";
import { DeviceUUID } from "device-uuid";

export const determineBASEURL = (e) => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_URL;
  }
  return `${window.location.origin}/api`;
};

const base64url = (source) => {
  var encodedSource = CryptoJS.enc.Base64.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, "");
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");

  return encodedSource;
};

export const JWT_Token = (appid) => {
  var deviceToken = new DeviceUUID().get();

  var header = {
    alg: "HS256",
    typ: "JWT",
  };

  const json = localStorage.getItem("Raw_id");
  let signedToken = null;
  if (json) {
    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = base64url(stringifiedHeader);
    var bytes = CryptoJS.AES.decrypt(json, process.env.REACT_APP_SECRET_KEY);
    const descryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const decrypt = JSON.parse(descryptedData);
    var Utc = new Date().toUTCString();
    var parsed = Date.parse(Utc) / 1000;
    var data = {
      ip: decrypt.ip,
      iss: "Web",
      sub: "security",
      "user-agent": navigator?.userAgent,
      device_token: deviceToken,
      city: decrypt.city,
      region: decrypt.region,
      country: decrypt.country,
      loc: decrypt.loc,
      timezone: decrypt.timezone,
      iat: parsed,
      exp: parsed + 60,
      app_id: process.env.REACT_APP_ID,
      client_type: "instacrick-app",
    };

    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));

    var encodedData = base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;

    var secret = process.env.REACT_APP_SECRET_KEY;

    var signature = CryptoJS.HmacSHA256(token, secret);
    signature = base64url(signature);
    signedToken = token + "." + signature;
  }

  return signedToken;
};
