import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import $ from "jquery";
import Navbar from "./Component/Navbars";
import Backg from "./assets/images/backg.png";
import Layer from "./assets/images/Layers.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Easy from "./assets/images/easy.png";
import Super from "./assets/images/super.png";
import Pro from "./assets/images/pro.png";
import Cloud from "./assets/images/cloud.png";
import Xml from "./assets/images/xml.png";
import Save from "./assets/images/save.png";
import Img1 from "./assets/images/img1.png";
import Img2 from "./assets/images/img2.png";
import Img3 from "./assets/images/img3.png";
import Img4 from "./assets/images/img4.png";
import Img5 from "./assets/images/img5.png";
import Img6 from "./assets/images/img6.png";
import Img7 from "./assets/images/img7.png";
import Vector1 from "./assets/images/Vector1.png";
import Vector2 from "./assets/images/Vector2.png";
import Vector3 from "./assets/images/Vector3.png";
import Vector4 from "./assets/images/Vector4.png";
import Vector5 from "./assets/images/Vector5.png";
import Vector6 from "./assets/images/Vector6.png";
import Feature from "./Component/Features";
import Graphics from "./Component/Graphics";
import Builds from "./Component/Build";
import Broads from "./Component/Broad";
import "./Home.css";
import { connect } from "react-redux";
import { ContactModalAction } from "./Actions/ContactModalAction";
import video from './assets/videos/landing-mission.mp4'
function Home(props) {
  React.useEffect(() => {
    $(document).ready(function () {
      var scrollLink = $(".scroll");

      // Smooth scrolling
      scrollLink.click(function (e) {
        if ($(this.hash).offset()) {
          e.preventDefault();
          $("body,html").animate(
            {
              scrollTop: $(this.hash).offset().top,
            },
            1000
          );
        }
      });
    });
  }, []);
  React.useEffect(() => {
    $(document).ready(function () {
      var scrollLink = $(".scroll-f");

      // Smooth scrolling
      scrollLink.click(function (e) {
        if ($(this.hash).offset()) {
          e.preventDefault();
          $("body,html").animate(
            {
              scrollTop: $(this.hash).offset().top,
            },
            1000
          );
        }
      });
    });
  }, []);
  React.useEffect(() => {
    $(document).ready(function () {
      var scrollLink = $(".scroll-b");

      // Smooth scrolling
      scrollLink.click(function (e) {
        if ($(this.hash).offset()) {
          e.preventDefault();
          $("body,html").animate(
            {
              scrollTop: $(this.hash).offset().top,
            },
            1000
          );
        }
      });
    });
  }, []);

  const setModalIsOpenToTrue = () => {
    props.contactModalAction({ type: "open", payload: {} });
  };

  return (
    <>
      <div className="container-fluid">
        <div>
          <Navbar />
        </div>
        <div
          className="pos-relative"
          style={{
            height: "100%",
            background: `url(${Backg}) no-repeat center/cover`,
            backgroundSize: "100% 100%",
            width: "calc(100% + 30px)",
            marginLeft: -15,
          }}
        >
          <div
            style={{
              position: "absolute",
              background: "linear-gradient(#0FB918 0%, #fff 100%)",
              left: 0,
              width: "calc(100% + 30px)",
              marginLeft: -15,
              height: "150%",
              opacity: 0.1,
            }}
          >
            <span style={{ visibility: "hidden" }}>...</span>
          </div>
          <div
            className="layer"
            style={{
              background: `url(${Layer}) no-repeat center/cover`,
              backgroundSize: "100% 100%",
            }}
          >
            <Row>
              <Col lg={{ span: 12 }}>
                <div className="big-n">
                  <div className="news-b">
                    <Button
                      size="sm"
                      variant="secondary"
                      className="light-shadow header-red"
                    >
                      BIG NEWS!
                    </Button>
                    <span> Instcric is Now Crickslab!</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 12 }}>
                <div className="graph">Cricket . Graphics . Scoring</div>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 12 }}>
                <div className="solution">
                  A complete cricket scoring solution for broadcasters and
                  organizers
                </div>
              </Col>
            </Row>
            <Row className="demo-s">
              <Col lg={{ span: 6 }}>
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-secondary mainBtn light-shadow btn-sm"
                    onClick={setModalIsOpenToTrue}
                  >
                    GET A DEMO <ArrowForwardIcon />
                  </Button>
                </div>
              </Col>
              <Col lg={{ span: 6 }}>
                <div className="d-flex feature justify-content-start">
                  <a
                    href="#features"
                    className="btn btn-secondary scroll-b light-shadow btn-sm"
                  >
                    SEE FEATURES <ArrowForwardIcon />
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="vdeo-s">
              <Col lg={{ span: 12 }}>
                <div className="d-flex white-mrg justify-content-center">
                  <div id="container-fluid" className="crickslabVideo">
                    <video
                      id="ViostreamIframe"
                      width="100%"
                      height="100%"
                      src={video}
                      autoPlay={true}
                      className="videoIframe"
                      muted={true}
                      controls={true}
                    ></video>
                  </div>
                </div>

                {/* <div className="d-flex white-mrg justify-content-end">
                  <div className="iframe-after"></div>
                  <iframe
                    frameborder="0"
                    height="90%"
                    width="100%"
                    title="Watch Our Demo"
                    src="https://crickslab-updated.netlify.app/assets/videos/landing-mission.mp4"
                    className="iframe"
                  />
                </div> */}
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <Row className="crds-pro">
              <Col lg={{ span: 4 }}>
                <div
                  className="easy-s"
                  style={{
                    background: `url(${Vector1}) no-repeat center/cover`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="inner"></div>
                  Easy Scoring
                  <p>
                    Easily manage all the problems you normally face when
                    scoring cricket matches.
                  </p>
                </div>
                <div className="diff-img">
                  <img src={Easy} alt="" />
                </div>
              </Col>
              <Col lg={{ span: 4 }}>
                <div
                  className="super-s"
                  style={{
                    background: `url(${Vector2}) no-repeat center/cover`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="inner"></div>
                  Super Fast Update
                  <p>
                    Update score once, reflect it intantly on TV, IOS, Android
                    and on your website
                  </p>
                </div>
                <div className="diff-img">
                  <img src={Super} alt="" />
                </div>
              </Col>
              <Col lg={{ span: 4 }}>
                <div
                  className="pro-s"
                  style={{
                    background: `url(${Vector3}) no-repeat center/cover`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="inner"></div>
                  PRO Graphics
                  <p>
                    Upgrade your cricket live stream with animated graphics to
                    give your users an international match experience.
                  </p>
                </div>
                <div className="diff-img">
                  <img src={Pro} alt="" />
                </div>
              </Col>
              <Col lg={{ span: 4 }}>
                <div
                  className="cloud-s"
                  style={{
                    background: `url(${Vector4}) no-repeat center/cover`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="inner"></div>
                  Cloud Based
                  <p>
                    Manage all the scores and graphics layout anywhere in any
                    platform.
                  </p>
                </div>
                <div className="diff-img">
                  <img src={Cloud} alt="" />
                </div>
              </Col>
              <Col lg={{ span: 4 }}>
                <div
                  className="xml-s"
                  style={{
                    background: `url(${Vector5}) no-repeat center/cover`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="inner"></div>
                  XML/Json Client
                  <p>
                    Easily integrate score data into your existing graphics
                    pipeline.
                  </p>
                </div>
                <div className="diff-img">
                  <img src={Xml} alt="" />
                </div>
              </Col>
              <Col lg={{ span: 4 }}>
                <div
                  className="save-s"
                  style={{
                    background: `url(${Vector6}) no-repeat center/cover`,
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="inner"></div>
                  Save Money
                  <p>
                    Reduce the labour needed to manually update scores in your
                    broadcast graphics.
                  </p>
                </div>
                <div className="diff-img">
                  <img src={Save} alt="" />
                </div>
              </Col>
            </Row>

            <Row className="most-s">
              <Col lg={{ span: 12 }}>
                <div className="trsted">
                  <p>Trsted by Most Popular Brands</p>
                </div>
                <div className="cricket-acad">
                  <img src={Img1} alt="" />
                  <img src={Img2} alt="" />
                  <img src={Img3} alt="" />
                  <img src={Img4} alt="" />
                  <img src={Img5} alt="" />
                  <img src={Img6} alt="" />
                  <img src={Img7} alt="" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row id="features">
          <Col lg={{ span: 12 }}>
            <Feature />
          </Col>
        </Row>
        <Row id="graphics">
          <Col lg={{ span: 12 }}>
            <Graphics />
          </Col>
        </Row>
        {/* <Row> */}
        {/* <Col lg={{ span: 12 }}> */}
        <Builds />
        {/* </Col> */}
        {/* </Row> */}
        <Row className="position-relative">
          <Col lg={{ span: 12 }}>
            <Broads />
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    contactModalState: state.contactModalState,
  };
};

const mapDispatchToProps = {
  contactModalAction: ContactModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
