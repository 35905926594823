
const initial_state = {
  modalIsOpen: false
}

const ContactModalReducer = (state = initial_state, action) => {
  switch (action.type) {
    case 'open':
      return {...state, modalIsOpen: true};
    case 'close':
      return {
        ...state,
        modalIsOpen: false
      }
    default:
      return state;
  }
 
}
export default ContactModalReducer;