import React from "react";

// import ReactPhoneInput from "react-phone-input";
import "react-phone-number-input/style.css";
// import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";

import axios from "axios";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { JWT_Token } from "../utils/jwt-utils";
// reactstrap components
import { Button, FormGroup, Form, Input, Container, Col } from "reactstrap";

const StyleSelect = {
  fontSize: "16px",
  height: "44px",
};
const Style = {
  padding: "20px",
  fontSize: "16px",
};
const phoneStyle = {
  padding: "10px",
  fontSize: "16px",
  border: "1px solid",
  borderColor: "#CACACA",
};
const FormStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const ButtonStyle = {
  backgroundColor: "#0fb918",
  color: "#ffffff",
  borderRadius: "2em",
  borderColor: "#0fb918",
};

const HeaderTextdescription = {
  marginBottom: "40px",
  fontFamily: "Mulish",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "25.6px",
  textAlign: "center",
};
class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone_number: "",
      country_code: "",
      email: "",
      message: "",
      type: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handlePhoneChange = (value) => {
    if (value !== undefined) {
      const phone = parsePhoneNumber(value);
      if (isValidPhoneNumber(value) === true) {
        this.setState({
          phone_number: phone.number,
          country_code: phone.countryCallingCode,
        });
      }
    }
  };

  handleChange(e, stateName) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    let newState = this.state;
    newState[stateName] = e.target.value;

    if (e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      state: newState,
    });
  }

  formSubmit = () => {
    const jsonWebToken = JWT_Token();

    if (
      this.state.name !== "" &&
      this.state.email !== "" &&
      this.state.type !== "" &&
      this.state.phone_number !== "" &&
      this.state.message !== ""
    ) {

      const data = {
        ...this.state,
        message: `Message From InstaCric: ${this.state.message}`,
      };
      axios
        .post(process.env.REACT_APP_API_VERSION, data, {
          headers: {
            "HANDSHAKE-TOKEN": jsonWebToken,
          },
        })
        .then((res) => {
          this.setState({
            alert: (
              <ReactBSAlert
                success
                style={{ display: "block" }}
                title="Success"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
              >
                Thank you for contacting us, we will get back to you soon
              </ReactBSAlert>
            ),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
    this.props.modalClose();
  };

  render() {
    return (
      <>
        {this.state.alert}
        <Container className="mt--6" fluid>
          <div className="row">
            <div className="col">
              <h4 style={HeaderTextdescription}>
                Please fill in the required details in below form and you will
                be contacted by our marketing department regarding your media
                inquiry. Please note that this contact form is for media-related
                topics only.
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form>
                <FormGroup className="row" style={FormStyle}>
                  <Col md="10">
                    <Input
                      name="name"
                      style={Style}
                      placeholder="Name*"
                      id="example-text-input"
                      type="text"
                      valid={this.state.nameState === "valid"}
                      invalid={this.state.nameState === "invalid"}
                      onChange={(e) => this.handleChange(e, "name")}
                    />
                    <div className="invalid-feedback">
                      Please choose a name.
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row" style={FormStyle}>
                  <Col md="10">
                    <Input
                      name="type"
                      id="exampleFormControlSelect1"
                      type="select"
                      style={StyleSelect}
                      valid={this.state.typeState === "valid"}
                      invalid={this.state.typeState === "invalid"}
                      onChange={(e) => this.handleChange(e, "type")}
                    >
                      <option>Select Type</option>
                      <option value="Products">Contact For Products</option>
                      <option value="Scoringwidgets">
                        {" "}
                        Website Scoring widget{" "}
                      </option>
                      <option value="Scoringapp">
                        {" "}
                        Tournament & Livescoring Module{" "}
                      </option>
                      <option value="Whitelabelapp">
                        {" "}
                        Whitelable Website & Apps{" "}
                      </option>
                      <option value="Overlays">
                        {" "}
                        Contact for livestraming overlays{" "}
                      </option>
                      <option value="Requestfordemo"> Request For Demo </option>
                      <option value="Technicalquestion">
                        {" "}
                        Technical Question
                      </option>
                      <option value="Technicalsupport"> Support </option>
                      <option value="Generalinquiry"> General Inquiry</option>
                      <option value="Other"> Other</option>
                    </Input>
                    <div className="invalid-feedback">
                      Please choose a type.
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row" style={FormStyle}>
                  <Col md="10">
                    <Input
                      name="email"
                      style={Style}
                      placeholder="Email"
                      id="example-email-input"
                      type="email"
                      valid={this.state.emailState === "valid"}
                      invalid={this.state.emailState === "invalid"}
                      onChange={(e) => this.handleChange(e, "email")}
                    />
                    <div className="invalid-feedback">
                      Please choose a email.
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row" style={FormStyle}>
                  <Col md="10">
                    <PhoneInput
                      country="UAE"
                      name="phone_number"
                      type="tel"
                      style={phoneStyle}
                      value={this.state.phone_number}
                      onChange={this.handlePhoneChange}
                      placeholder="Enter phone number"
                    />
                    <div className="invalid-feedback">
                      Please choose a phone_number.
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row" style={FormStyle}>
                  <Col md="10">
                    <Input
                      name="message"
                      style={Style}
                      height="140px"
                      id="example-number-input"
                      type="textarea"
                      placeholder="Write Message"
                      valid={this.state.messageState === "valid"}
                      invalid={this.state.messageState === "invalid"}
                      onChange={(e) => this.handleChange(e, "message")}
                    />
                    <div className="invalid-feedback">
                      Please choose a message.
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row" style={FormStyle}>
                  <Col md="10">
                    <Button
                      className="btn-block"
                      type="button"
                      style={ButtonStyle}
                      onClick={this.formSubmit}
                    >
                      Submit
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ContactForm;
