import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk'
import ContactModalReducer from './Reducers/ContactModalReducer';

/**
 * Configuration of store
 */
const InstaStore = () => {
    const rootReducer = combineReducers({
        contactModalState: ContactModalReducer
    });
    return createStore(rootReducer, applyMiddleware(thunk));
}
export default InstaStore;