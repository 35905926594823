import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "./assets/images/logo.png";
import Lab from "./assets/images/lab.png";
import Backg from "./assets/images/backg.png";
import "./Component.css";
import { Button, Modal } from "reactstrap";
import ContactForm from "./ContactForm";
import { connect } from "react-redux";
import { ContactModalAction } from "../Actions/ContactModalAction";

function Navbars(props) {
  
  const modalIsOpen = props.contactModalState.modalIsOpen;

  const setModalIsOpenToTrue = () => {
    props.contactModalAction({type: 'open', payload: {} });
  }
  
  const setModalIsOpenToFalse = () => {
    props.contactModalAction({type: 'close', payload: {} });
  }

  
  return (
    <div className="pos-relative">
      <div
        style={{
          position: "absolute",
          background: "#0FB918",
          left: 0,
          width: "calc(100% + 30px)",
          marginLeft: -15,
          height: "100%",
          opacity: 0.1,
        }}
      >
        <span style={{ visibility: "hidden" }}>...</span>
      </div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        style={{
          background: `url(${Backg}) no-repeat center/cover`,
          backgroundSize: "100% 100%",
        }}
      >
        <Navbar.Brand href="#home">
          <div className="nvbr-brnd">
            <img src={Logo} className="logo" alt="" />{" "}
            <div className="nav-separetor"></div>{" "}
            <img src={Lab} className="lab" alt="" />
          </div>{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <div className="menu">
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>

          <Nav>
            <a className="scroll nav-scroll" href="#features">
              Features
            </a>
            <a className="scroll nav-scroll" href="#graphics">
              Graphics
            </a>
            <Nav.Link href="#deets">
              <div className="sign-i">
                <Button
                  size="sm"
                  style={{ cursor: "pointer" }}
                  variant="secondary"
                  className="light-shadow mainBtn"
                  onClick={setModalIsOpenToTrue}
                >
                  Contact Us
                </Button>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Modal
        size='lg'
        className="modal-dialog-centered ContactModal"
        isOpen={modalIsOpen}
      >
        <div className="modal-header">
          <h2 className="modal-title w-100 text-center">HOW TO CONTACT US?</h2>
          <button
            aria-label="Close"
            style={{ fontSize: "22px", cursor: "pointer" }}
            className="close float-right"
            data-dismiss="modal"
            type="button"
            onClick={setModalIsOpenToFalse}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body p-5 rounded-lg">
          <ContactForm modalClose={setModalIsOpenToFalse}/>
        </div>
      </Modal>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    contactModalState: state.contactModalState,
  };
};

const mapDispatchToProps = {
  contactModalAction: ContactModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbars);

